import type { SvgVariant } from '$lib/components/icon/icons';

export const THEMES = ['off-white', 'ultraviolet', 'green', 'pink'] as const;

export type Theme = (typeof THEMES)[number];

export const iconVariants: Record<Theme, SvgVariant> = {
  'off-white': 'default',
  ultraviolet: 'purple-ultraviolet-gradient',
  green: 'green-gradient',
  pink: 'pink-gradient',
};
